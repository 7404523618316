import React from "react";

/**
 * Component for the section headings; sets the right font size and adds the custom
 * green underline.
 * @param {*} props
 *
 * @author Daniel Gomm <alphapaka89@gmail.com>
 * @copyright Launch Site LLC
 */
const SectionHeading = props => {
  return (
    <>
      <h1
        className="section-heading"
        style={props.color ? { color: props.color } : {}}
      >
        {props.title}
        <div className="underline"></div>
      </h1>
    </>
  );
};

export default SectionHeading;
