import React from "react";
import Grid from "@material-ui/core/Grid";

import BlobContainer from "../utils/BlobContainer";
import lily from "../../assets/images/lily-pad-3.png";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";
import { Hidden } from "@material-ui/core";
import svgBottom from "../../assets/images/testimonialSvgBottom.svg";
import svgTop from "../../assets/images/testimonialSvgTop.svg";
import SvgContainer from "../utils/SvgContainer";

const useStyles = makeStyles({
  borderBox: {
    width: "40vw",
    height: "45vh"
  },
  gridMargin: {
    marginTop: "2em",
    marginBottom: "2em"
  }
});

const MobileAbout = () => {
  return (
    <SvgContainer
      svgBottom={svgBottom}
      svgTop={svgTop}
      initialHeight={"75vh"}
      minHeight={"75vh"}
      sectionContent={
        <div
          style={{
            height: "100%",
            width: "100%"
          }}
          className="verticalHorizontalDiv"
        >
          <Grid container alignItems="center" justify="center">
            <Grid item sm={12}>
              <BlobContainer
                height="35vh"
                width="75vw"
                className="lillyBlobContainer"
                backgroundImage={lily}
                innerComponent={
                  <h1
                    style={{
                      padding: "0px",
                      margin: "0px",
                      marginBottom: ".5em"
                    }}
                    className="section-heading"
                  >
                    Did You Know?
                  </h1>
                }
              />
            </Grid>
            <Grid item sm={12} md={5}>
              <Grid align="center" item sm={12} md={12}>
                <div className="mobileAboutText sectionText">
                  <h2>
                    There are several environmental benefits of building a pond!
                  </h2>
                  <h2>
                    Ponds provide sanctuary by offering food, water, shelter,
                    and a place for breeding of indigenous wildlife like
                    dragonflies, frogs, and birds.
                  </h2>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
};

const DesktopAbout = () => {
  const classes = useStyles();

  return (
    <BlobContainer
      height="90vh"
      width="50vw"
      backgroundImage={lily}
      innerComponent={
        <Grid
          className={classes.gridMargin}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item sm={12} md={6}>
            <Grid align="center" item sm={12} md={12}>
              <div>
                <h1 className="section-heading" style={{ fontSize: "1.5em" }}>
                  Did You Know?
                </h1>
              </div>
            </Grid>
            <Grid align="center" item sm={12} md={12}>
              <div className="features-heading sectionText">
                <h2>
                  There are several environmental benefits of building a pond!
                </h2>
                <h2>
                  Ponds provide sanctuary by offering food, water, shelter, and
                  a place for breeding of indigenous wildlife like dragonflies,
                  frogs, and birds.
                </h2>
              </div>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

const AboutSection = props => {
  return (
    <div className="aboutSection verticalHorizontalDiv">
      <Fade>
        <Hidden mdUp>
          <MobileAbout />
        </Hidden>
        <Hidden smDown>
          <DesktopAbout />
        </Hidden>
      </Fade>
    </div>
  );
};

export default AboutSection;
