import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export class GridSectionItemDTO {
  key: string = uuidv4();
  src: string = "";
  title: string = "";
  subTitle: string = "";

  public constructor(gridSectionItem?: Partial<GridSectionItemDTO>) {
    Object.assign(this, gridSectionItem);
  }
}
