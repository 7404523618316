import React from "react";
import Testimonials from "../utils/Testimonials";
import { Element } from "react-scroll";
import koiClients from "../../assets/images/koi-clients.png";

import BlobContainer from "../utils/BlobContainer";
import { Hidden } from "@material-ui/core";
import SvgContainer from "../utils/SvgContainer";
import svgBottom from "../../assets/images/testimonialSvgBottom.svg";
import svgTop from "../../assets/images/testimonialSvgTop.svg";

import koiDark from "../../assets/images/koiDark2.png";

const testimonials = [
  {
    rating: 5,
    description:
      "Christian did an awesome job! I'm thrilled with my new custom water feature.",
  },
  {
    rating: 5,
    description:
      "East Coast Aquascape took my ideas, and ran with them. My backyard is so peaceful and inviting since the pond installation. Can't thank them enough!!",
  },
  {
    rating: 5,
    description:
      "Wow, I always wanted a pond... I never knew what I was missing! I'm happy I called East Coast Aquascape. The koi are such a nice bonus",
  },
  {
    rating: 5,
    description:
      "Christian is a great guy, and pays close attentian to detail. Highly recommend 5/5",
  },
];

const MobileTestimonials = () => {
  return (
    <SvgContainer
      backgroundImage={koiClients}
      svgBottom={svgBottom}
      svgTop={svgTop}
      initialHeight={"75vh"}
      minHeight={"75vh"}
      sectionContent={
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#00000082",
          }}
          className="verticalHorizontalDiv"
        >
          <div
            style={{ height: "100%", width: "100%" }}
            className="verticalHorizontalDiv"
          >
            <Testimonials
              title="Client Testimonials"
              subTitle="See why our clients love us!"
              items={testimonials}
            />
          </div>
        </div>
      }
    />
  );
};

const DesktopTestimonials = () => {
  return (
    <BlobContainer
      style={{ zIndex: 10 }}
      height="100vh"
      width="100vw"
      backgroundImage={koiDark}
      contain
      backgroundPosition="left"
      innerComponent={
        <div style={{ marginTop: "15%" }}>
          <Testimonials
            title="Client Testimonials"
            subTitle="See why our clients love us!"
            items={testimonials}
          />
        </div>
      }
    />
  );
};

const TestimonialsSection = (props) => {
  return (
    <Element name="testimonials">
      <div className="verticalHorizontalDiv whiteText testimonialsSection">
        <Hidden mdUp>
          <MobileTestimonials />
        </Hidden>
        <Hidden smDown>
          <DesktopTestimonials />
        </Hidden>
      </div>
    </Element>
  );
};

export default TestimonialsSection;
