import React, { Component } from "react";
import Fade from "react-reveal/Fade";

class BorderBox extends Component {
  state = {};
  render() {
    const {
      outerBorderColor,
      outerBorderRadius,
      innerBorderColor,
      innerBorderRadius
    } = this.props;
    return (
      <Fade>
        <div
          className="borderBoxOuter"
          style={{
            backgroundColor: outerBorderColor,
            borderRadius: outerBorderRadius ? outerBorderRadius : "0px"
          }}
        >
          <div
            className="borderBoxInner"
            style={{
              borderColor: innerBorderColor,
              borderRadius: innerBorderRadius ? innerBorderRadius : "0px",
              backgroundImage: this.props.image
            }}
          ></div>
        </div>
      </Fade>
    );
  }
}

export default BorderBox;
