import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import { withStyles } from "@material-ui/core/styles";
import { green, purple } from "@material-ui/core/colors";

/**
 * The primary button, used throughout the site. It wraps over the material ui default button.
 *
 * @author Daniel Gomm <alphapaka89@gmail.com>
 * @copyright Launch Site LLC
 */
const PrimaryButton = withStyles(theme => ({
  root: {
    color: "white",
    backgroundColor: "#858BE5",
    zIndex: 1,
    fontSize: "1em",
    padding: ".5em 2.5em",
    borderRadius: "30px",
    textTransform: "none",
    fontFamily: "'Josefin Sans', sans-serif !important",

    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#6771FF"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.25em"
    }
  }
}))(Button);

// export default PrimaryButton;

// const useStyles = makeStyles({
//   colorPrimary: {
//     backgroundColor: "White",
//     color: "##3d997091"
//   },

//   blobMargin: {
//     marginTop: "3em"
//   }
// });

// const PrimaryButton = props => {
//   const classes = useStyles();

//   return <Button className="primaryButton"> {props.text}</Button>;
// };

export default PrimaryButton;
