import React, { Component } from "react";

/**
 * Container with optional parallax scrolling background image that can optionally include svg graphics to style
 * the top and bottom.
 *
 * @param {*} props
 * @copyright Launch Site LLC
 */
class SvgContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageHeader: React.createRef()
    };
  }

  componentDidMount() {
    const { parallax } = this.props;
    const boundingRect = this.state.pageHeader.current.getBoundingClientRect();

    // The y position is the bounding rect's y plus the current scroll (if the page loads partially
    // scrolled, boundingRect.y is relative to the starting scroll, not the actual top of the page)
    const yPos = boundingRect.y + window.pageYOffset;

    // Add parallax scrolling to background only if it was specified in props
    if (parallax) {
      const updateScroll = () => {
        let windowScrollTop = Math.max(0, window.pageYOffset - yPos) / 3;

        if (this.state.pageHeader.current == null) {
          this.setState({ pageHeader: React.createRef() }, () => {
            this.setState({
              [this.state.pageHeader.current.style.transform]:
                "translate3d(0," + windowScrollTop + "px,0)"
            });
          });
        } else {
          this.state.pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";
        }
      };
      window.addEventListener("scroll", updateScroll);

      this.setState({
        ...this.state,
        cleanup: () => {
          window.removeEventListener("scroll", updateScroll);
        }
      });
    }
  }

  componentWillUnmount() {
    // this.state.cleanup();
  }

  render() {
    const {
      initialHeight,
      minHeight,
      className,
      svgBottom,
      svgTop,
      backgroundImage,
      sectionContent
    } = this.props;

    /**
     * @var pageHeader Reference to the element with the background image
     */
    // let pageHeader = React.createRef();

    return (
      <>
        <div
          style={{
            height: initialHeight ? initialHeight : "auto",
            minHeight: minHeight ? minHeight : "100vh",
            maxHeight: "300vh",
            color: "black"
          }}
          className={`page-header clear-filter ${className}`}
        >
          <div
            className="page-header-image"
            style={{
              backgroundImage: `url(${backgroundImage})`
            }}
            ref={this.state.pageHeader}
          ></div>
          {svgTop ? (
            <div
              className="svgTop"
              style={{
                background: `url(${svgTop})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top",
                backgroundSize: "100%",
                zIndex: 1
              }}
            />
          ) : null}
          {svgBottom ? (
            <div
              className="svgBottom"
              style={{
                background: `url(${svgBottom}`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
                backgroundSize: "100%"
              }}
            />
          ) : null}

          {sectionContent}
        </div>
      </>
    );
  }
}

export default SvgContainer;
