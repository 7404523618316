import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import PrimaryButton from "../PrimaryButton";

const styles = theme => ({
  formStyle: {
    height: "100%",
    width: "100%"
  },
  form: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      minHeight: "600px",
      height: "600px"
    }
  }
});

class ContactForm extends Component {
  state = {
    email: "",
    phoneNumber: "",
    name: "",
    message: "",
    sucess: false
  };

  componentDidMount() {
    const phoneNumberRegex = new RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/);
    // custom rule will have name 'isPhoneNumberValid'
    ValidatorForm.addValidationRule("isPhoneNumberValid", value => {
      return phoneNumberRegex.test(value);
    });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    var message = {
      name: this.state.name,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      message: this.state.message
    };

    console.log(message);

    fetch("/api/messages", {
      method: "POST",
      body: JSON.stringify(message),
      headers: { "Content-Type": "application/json" }
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({ success: true });
        } else {
          this.setState({ success: false });
        }

        // console.log(response.json());
        return response.json();
      })
      .then(body => {
        console.log(body);
      });
  };

  render() {
    const { classes } = this.props;
    const { name, email, phoneNumber, message, success } = this.state;
    return success ? (
      <div
        style={{ height: "18em" }}
        className="verticalHorizontalDiv blackText"
      >
        <p>{`Thanks for reaching out, ${name}! We'll get back to you shortly. Thanks for choosing East Coast Aquascape.`}</p>
      </div>
    ) : (
      <Grid
        style={{ minWidth: "250px", height: "25em" }}
        container
        justify="center"
        alignItems="center"
      >
        <ValidatorForm
          ref="form"
          className={classes.form}
          onSubmit={this.handleSubmit}
          onError={errors => console.log(errors)}
        >
          <Grid style={{ marginBottom: "3em", height: "3em" }} item sm={12}>
            <label>
            <TextValidator
              label="Name"
              onChange={this.handleChange}
              name="name"
              value={name}
              validators={["required"]}
              errorMessages={["this field is required"]}
              fullWidth
            />
            </label>
          </Grid>
          <Grid style={{ marginBottom: "3em", height: "3em" }} item sm={12}>
          <label>
            <TextValidator
              label="Email"
              onChange={this.handleChange}
              name="email"
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={["this field is required", "email is not valid"]}
              fullWidth
            /></label>
          </Grid>
          <Grid style={{ marginBottom: "3em", height: "3em" }} item sm={12}>
          <label>
            <TextValidator
              label="Phone Number"
              onChange={this.handleChange}
              name="phoneNumber"
              value={phoneNumber}
              validators={["required", "isPhoneNumberValid"]}
              errorMessages={[
                "this field is required",
                "phone number is not valid"
              ]}
              fullWidth
            />
          </label>
          </Grid>
          <Grid style={{ marginBottom: "3em", height: "3em" }} item sm={12}>
          <label>
            <TextValidator
              label="Message"
              onChange={this.handleChange}
              name="message"
              value={message}
              validators={["required"]}
              errorMessages={["this field is required"]}
              fullWidth
            />
          </label>
          </Grid>
          <Grid style={{ marginBottom: "3em" }} item sm={12}>
            <PrimaryButton onClick={() => this.handleSubmit()}>
              Submit
            </PrimaryButton>{" "}
          </Grid>
        </ValidatorForm>
      </Grid>
    );
  }
}

export default withStyles(styles)(ContactForm);
