import React, { Component } from "react";
import { Link, scroller, animateScroll as scroll } from "react-scroll";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import HomeIcon from "@material-ui/icons/Home";
import ListIcon from "@material-ui/icons/List";
import InfoIcon from "@material-ui/icons/Info";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import NoteIcon from "@material-ui/icons/Note";
import logo from "../../assets/images/logoKoi2.png";

import ForumIcon from "@material-ui/icons/Forum";

const useStyles = theme => ({
  colorPrimary: {
    backdropFilter: "blur(5px)",
    boxShadow: "none",
    backgroundColor: "#3D9970",
    color: "white"
  },
  menuButton: {
    backgroundColor: "white",
    position: "absolute"
  }
});

/**
 * The landing page navigation bar for mobile screens.
 *
 * @author Daniel Gomm <alphapaka89@gmail.com>
 * @copyright Launch Site LLC
 */
class MobileNavigationBar extends Component {
  /**
   * Initializes the state
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      bSidebarOpen: false
    };
  }

  /**
   * Toggles the sidebar menu
   */
  toggleSideBar() {
    this.setState({
      ...this.state,
      bSidebarOpen: !this.state.bSidebarOpen
    });
  }

  render() {
    const { classes } = this.props;
    const { bSidebarOpen } = this.state;
    return (
      <>
        <Drawer
          anchor={"left"}
          open={bSidebarOpen}
          onClose={() => this.toggleSideBar()}
          style={{
            width: "30%"
          }}
        >
          <div
            style={{
              width: "250px"
            }}
          >
            <List>
              <Link
                to="home"
                smooth={true}
                duration={500}
                onClick={() => this.toggleSideBar()}
              >
                <ListItem button>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Home"} />
                </ListItem>
              </Link>

              <Link
                to="services"
                smooth={true}
                duration={500}
                onClick={() => this.toggleSideBar()}
              >
                <ListItem button>
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Services"} />
                </ListItem>
              </Link>
              <Link
                to="process"
                smooth={true}
                duration={500}
                onClick={() => this.toggleSideBar()}
              >
                <ListItem button>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Process"} />
                </ListItem>
              </Link>

              <Link
                to="maintenance"
                smooth={true}
                duration={500}
                onClick={() => this.toggleSideBar()}
              >
                <ListItem button>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Maintenance"} />
                </ListItem>
              </Link>

              <Link
                to="testimonials"
                smooth={true}
                duration={500}
                onClick={() => this.toggleSideBar()}
              >
                <ListItem button>
                  <ListItemIcon>
                    <ForumIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Testimonials"} />
                </ListItem>
              </Link>

              <Link
                to="contact"
                smooth={true}
                duration={500}
                onClick={() => this.toggleSideBar()}
              >
                <ListItem button>
                  <ListItemIcon>
                    <ForumIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Contact"} />
                </ListItem>
              </Link>
            </List>
          </div>
        </Drawer>
        <AppBar
          classes={{ colorPrimary: classes.colorPrimary }}
          style={{ padding: "5px" }}
        >
          <Toolbar>
            <Hidden mdUp>
              <IconButton
                className={classes.menuButton}
                edge="start"
                color="primary"
                onClick={() => this.toggleSideBar()}
                aria-label="Navigation bar"
              >
                <MenuIcon />
              </IconButton>
              <Grid alignItems="center" justify="center" container>
                <Grid align="center" item xs={12} sm={12}>
                  <img src={logo} alt="East Coast Aquascape logo" style={{ height: "55px", margin: "auto" }} />
                </Grid>
                <Grid
                  style={{ marginTop: ".5em" }}
                  align="center"
                  item
                  xs={12}
                  sm={12}
                >
                  <a className="whiteText" href="tel:+1-631-355-9546">
                    <span className="phoneNumber whiteText">631-355-9546</span>
                  </a>
                </Grid>
              </Grid>
            </Hidden>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

export default withStyles(useStyles)(MobileNavigationBar);
