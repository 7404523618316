import React from "react";
import Grid from "@material-ui/core/Grid";
import InstagramEmbed from "react-instagram-embed";
import { Element } from "react-scroll";

import { makeStyles } from "@material-ui/core/styles";
import { borderRadius } from "@material-ui/system";
import SectionHeading from "../utils/SectionHeading";
import Fade from "react-reveal/Fade";
import PrimaryButton from "../utils/PrimaryButton";
import lillies from "../../assets/images/lillies3.png";
import { Hidden } from "@material-ui/core";
// require("dotenv").config({ path: "../../../.env" });
require("dotenv").config();

const useStyles = makeStyles((theme) => ({
  borderBox: {
    width: "35vw",
    height: "35vh",
  },
  gridMargin: {
    marginTop: "2em",
    marginBottom: "2em",
    zIndex: 3,
  },
  backgroundLillies: {
    height: "75%",
    width: "100%",
    position: "absolute",
    zIndex: 1,
    borderRadius: "100px",
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${lillies})`,
    },
  },
}));

const title = "We're on Instagram!";
const subTitle =
  "Check us out on Instagram for awesome aquascape ideas and inspiration";

const InstagramSection = (props) => {
  const classes = useStyles();

  return (
    <Element name="instagram">
      <div className="instagramSection features-heading welcomeSecion verticalHorizontalDiv">
        <div className={classes.backgroundLillies} />
        <Grid
          className={classes.gridMargin}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item sm={12} md={3}>
            <Grid align="center" item sm={12} md={12}>
              <SectionHeading title={title} />
            </Grid>
            <Grid align="center" item sm={12} md={12}>
              <h2 style={{ paddingLeft: "2em", paddingRight: "2em" }}>
                {subTitle}
              </h2>
            </Grid>
            <Hidden smDown>
              <Grid style={{ marginTop: "2.5em" }} item sm={12} align="center">
                <PrimaryButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/east_coast_aquascape/?hl=en"
                    )
                  }
                >
                  Check Out Our IG
                </PrimaryButton>
              </Grid>
            </Hidden>
          </Grid>
          <Grid align="center" item sm={12} md={5}>
            <Fade>
              <InstagramEmbed
                url="https://www.instagram.com/p/CBqDw21HV29/"
                clientAccessToken={process.env.REACT_APP_CLIENT_ACCESS_TOKEN}
                maxWidth={320}
                hideCaption={false}
                containerTagName="div"
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={() => {}}
                onFailure={() => {}}
              />
            </Fade>
          </Grid>
          <Hidden mdUp>
            <Grid style={{ marginTop: "2.5em" }} item sm={12} align="center">
              <PrimaryButton
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/east_coast_aquascape/?hl=en"
                  )
                }
              >
                Check Out Our IG
              </PrimaryButton>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </Element>
  );
};

export default InstagramSection;
