import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import SectionHeading from "../utils/SectionHeading";
import CustomTimeline from "../utils/CustomTimeline/CustomTimeline";
import rightBlob from "../../assets/images/blue-right.png";
import BlobContainer from "../utils/BlobContainer";
import { Element } from "react-scroll";

import landscapeMobile from "../../assets/images/landscapeMobile.svg";
import fountainMobile from "../../assets/images/fountainMobile.svg";
import calendarMobile from "../../assets/images/calendarMobile.svg";
import shovelMobile from "../../assets/images/shovelMobile.svg";

const DesktopProcess = () => {
  return (
    <BlobContainer
      // className="backgroundRight"
      style={{ zIndex: 10 }}
      height="100%"
      width="100vw"
      backgroundImage={rightBlob}
      contain
      backgroundPosition="right"
      innerComponent={
        <Grid container justify="center" alignItems="center" direction="row">
          <Grid item sm={12} style={{ marginBottom: "7em" }}>
            <SectionHeading title="You'll Enjoy Your Outdoor Oasis in no Time" />
          </Grid>
          <Grid item sm={12}>
            <CustomTimeline />
          </Grid>
        </Grid>
      }
    />
  );
};

const MobileProcess = () => {
  return (
    <div className="mobileProcessText">
      <Grid container justify="center">
        <Grid item sm={12} style={{ marginBottom: "7em" }}>
          <SectionHeading title="You'll Enjoy Your Outdoor Oasis in no Time" />
        </Grid>
        <Grid item align="right" xs={6} sm={6}>
          <div
            className="containBackground processIcon"
            style={{
              backgroundImage: `url(${calendarMobile})`
            }}
          />
        </Grid>
        <Grid item align="left" xs={6} sm={6}>
          <h1>Free Consultation</h1>
          <h2>
            We'll evaluate your property and ideas, and begin to brainstorm
            ideas of our own
          </h2>
        </Grid>

        <Grid item align="right" xs={6} sm={6}>
          <div
            className="containBackground processIcon"
            style={{
              backgroundImage: `url(${landscapeMobile})`
            }}
          />
        </Grid>
        <Grid item align="left" xs={6} sm={6}>
          <h1>Survey Land & Draft Designs</h1>
          <h2>
            We'll pick the ideal location for your pond, and begin drafting our
            ideas
          </h2>
        </Grid>

        <Grid item align="right" xs={6} sm={6}>
          <div
            className="containBackground processIcon"
            style={{
              backgroundImage: `url(${shovelMobile})`
            }}
          />
        </Grid>
        <Grid item align="left" xs={6} sm={6}>
          <h1>Preparation & Installation</h1>
          <h2>
            We'll break ground and begin making your dream pond a reality!
          </h2>
        </Grid>

        <Grid item align="right" xs={6} sm={6}>
          <div
            className="containBackground processIcon"
            style={{
              backgroundImage: `url(${fountainMobile})`
            }}
          />
        </Grid>
        <Grid item align="left" xs={6} sm={6}>
          <h1>Enjoy!</h1>
          <h2>Woohoo! It's time for you to enjoy your backyard oasis!</h2>
        </Grid>
      </Grid>
    </div>
  );
};

const OurProcessSection = () => {
  return (
    <Element name="process">
      <div className="ourProcessSection">
        <Hidden mdUp>
          <MobileProcess />
        </Hidden>
        <Hidden smDown>
          <DesktopProcess />
        </Hidden>
      </div>
    </Element>
  );
};

export default OurProcessSection;
