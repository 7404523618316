import React, { Component } from "react";

class SliderComponent extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div
        className="sliderItems verticalHorizontalDiv"
        style={{
          height: "100%",
          width: "100%"
        }}
      >
        <div className="verticalHorizontalDiv" style={{ width: "75%" }}>
          <p style={{ color: "white", marginBottom: "0px" }}>
            {this.props.description}
          </p>
        </div>
      </div>
    );
  }
}

export default SliderComponent;
