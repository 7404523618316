import React from "react";
import { Link, scroller, animateScroll as scroll } from "react-scroll";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

/**
 * Site footer for mobile screens.
 *
 * @author Daniel Gomm <alphapaka89@gmail.com>
 * @copyright Launch Site LLC
 */
const MobileFooter = () => {
  return (
    <div className="footer">
      <div className="center">
        <p>East Coast Aquascape</p>
        <span style={{ color: "#3d996f" }}>
          <FacebookIcon className="socialIcon" />
          <TwitterIcon className="socialIcon" />
          <InstagramIcon className="socialIcon" />
          <LinkedInIcon className="socialIcon" />
        </span>
      </div>
      <div className="center">
        <Link to="about" smooth={true} duration={500}>
          About Us
        </Link>
        <Link to="process" smooth={true} duration={500}>
          Our Process
        </Link>
        <Link to="services" smooth={true} duration={500}>
          Services
        </Link>
        <Link to="maintenance" smooth={true} duration={500}>
          Maintenance
        </Link>
        <Link to="testimonials" smooth={true} duration={500}>
          Testimonials
        </Link>
        <Link to="contact" smooth={true} duration={500}>
          Contact
        </Link>
      </div>
      <div className="center">
        <p>
          Powered by{" "}
          <a href="https://www.launchsitellc.com/" target="_blank" rel="noreferrer">
            Launch Site LLC
          </a>
        </p>
        <p>All rights reserved &copy; 2020</p>
      </div>
    </div>
  );
};

export default MobileFooter;
