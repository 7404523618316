import React from "react";
import { Link, scroller, animateScroll as scroll } from "react-scroll";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import MobileNavigationBar from "./MobileNavigationBar";
import DesktopNavigationBar from "./DesktopNavigationBar";

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: "White",
    color: "#59315B",
  },
  menuButton: {
    backgroundColor: "white",
    position: "absolute",
  },
});

/**
 * The landing page navigation bar, which is responsive and will transition to a mobile toggle sidebar
 * on smaller screens.
 *
 * @author Daniel Gomm <alphapaka89@gmail.com>
 * @copyright Launch Site LLC
 */
const NavigationBar = () => {
  return (
    <>
      <Hidden mdUp>
        <MobileNavigationBar />
      </Hidden>
      <Hidden smDown>
        <DesktopNavigationBar />
      </Hidden>
    </>
  );
};

export default NavigationBar;
