import React from "react";
import BlobContainer from "../utils/BlobContainer";
import { Element } from "react-scroll";
import SvgContainer from "../utils/SvgContainer";
import pondBlob from "../../assets/images/pond-blob-rectangle-2.png";
import Hidden from "@material-ui/core/Hidden";
import lilyBlob from "../../assets/images/heroAlt2.png";
import mobileLillies from "../../assets/images/lilliesMobile.png";
import { makeStyles } from "@material-ui/core/styles";
import heroBottom from "../../assets/images/heroVector.svg";
import PrimaryButton from "../utils/PrimaryButton";
import heroSvgSmall from "../../assets/images/heroSvgSmall.svg";
import { Link, animateScroll as scroll } from "react-scroll";

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: "White",
    color: "##3d997091",
  },

  blobMargin: {
    marginTop: "3em",
  },
});

const HeroContent = () => {
  const classes = useStyles();

  return (
    <div className="whiteText">
      <h1 className="heroHeader">East Coast Aquascape</h1>
      <h2
        style={{ marginTop: "1em", marginBottom: "1em", marginRight: "25%" }}
        className="heroSubHeader"
      >
        A Water View Just For You
      </h2>
      <Link to="contact" spy={true} smooth={true} duration={700}>
        <PrimaryButton>Schedule Your Free Consultation</PrimaryButton>
      </Link>
    </div>
  );
};

const MobileHero = () => {
  return (
    <SvgContainer
      parallax
      backgroundImage={mobileLillies}
      svgBottom={heroSvgSmall}
      sectionContent={
        <div
          style={{ height: "100%", width: "100%" }}
          className="verticalHorizontalDiv"
        >
          <div
            style={{ height: "50%", width: "100%", paddingLeft: "2em" }}
            className="verticalHorizontalDiv"
          >
            <HeroContent />
          </div>
        </div>
      }
    />
  );
};

const DesktopHero = () => {
  return (
    <SvgContainer
      parallax
      // backgroundImage={heroImage}
      svgBottom={heroBottom}
      style={{ zIndex: 1 }}
      sectionContent={
        <div style={{ zIndex: 2 }} className="verticalHorizontalDiv">
          <BlobContainer
            style={{ zIndex: 10 }}
            height="90vh"
            width="100vw"
            backgroundImage={lilyBlob}
            innerComponent={<HeroContent />}
          />
        </div>
      }
    />
  );
};

const BlobHeroSection = () => {
  const classes = useStyles();

  return (
    <Element
      style={{
        background: "#3d997091",
        marginBottom: "3em",
        display: "flex",
      }}
      name="home"
    >
      <Hidden mdUp>
        <MobileHero />
      </Hidden>
      <Hidden smDown>
        <DesktopHero />
      </Hidden>
    </Element>
  );
};

export default BlobHeroSection;
