import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { minWidth } from "@material-ui/system";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100vw",
    height: "100%",
    justifyContent: "center",
    padding: "1em",
    alignItems: "center",
    overflow: "hidden",
    color: "white",
    minWidth: "100vw",

    [theme.breakpoints.up("lg")]: {
      paddingLeft: "100px",
      paddingRight: "100px"
    }
  },

  inner: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      width: "80%",
      height: "80%"
    }
  }
}));

const GridSection = props => {
  const classes = useStyles();

  const { items, colsLg, colsSm, colsXs, tileClasses } = props;
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <Grid container justify="center" alignItems="center" spacing={4}>
          {items.map(item => (
            <>
              <Grid item xs={12 / colsXs} sm={12 / colsSm} md={12 / colsLg}>
                <div
                  className={`coverBackground verticalHorizontalDiv ${tileClasses} `}
                  style={{
                    backgroundImage: `url(${item.src})`,
                    backgroundColor: item.color ? item.color : "white"
                  }}
                >
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              </Grid>
            </>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default GridSection;
