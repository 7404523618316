import React, { Component } from "react";

class BlobContainer extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const {
      cover,
      contain,
      width,
      height,
      backgroundImage,
      className,
      backgroundPosition
    } = this.props;
    return (
      <div
        className={`${
          cover ? "blobContainerCover" : "blobContainerContain"
        } ${className}`}
        style={{
          height: this.props.height,
          width: this.props.width,
          zIndex: 1000,
          backgroundImage: `url(${this.props.backgroundImage})`,
          backgroundPosition: backgroundPosition
        }}
      >
        {/*center the innter component in the blob*/}
        <div
          style={{ width: "100%", height: "100%" }}
          className="verticalHorizontalDiv"
        >
          {this.props.innerComponent}
        </div>
      </div>
    );
  }
}

export default BlobContainer;
