import React from "react";
import { Link, scroller, animateScroll as scroll } from "react-scroll";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import logo from "../../assets/images/logoKoi2.png";
import { Button } from "@material-ui/core";

/**
 * Site footer for desktop screens.
 *
 * @author Daniel Gomm <alphapaka89@gmail.com>
 * @copyright Launch Site LLC
 */
const DesktopFooter = () => {
  return (
    <Grid alignItems="center" className="footer" container>
      <Grid className="left" container item sm={4}>
        <Grid item sm={12}>
          <p>East Coast Aquascape</p>
          <a
            style={{ color: "black !important" }}
            className="blackText"
            href="tel:+1-631-355-9546"
          >
            <span className="desktopPhoneNumber phoneNumber blackText">
              631-355-9546
            </span>
          </a>
          <span style={{ color: "#3d996f", fontSize: "2em" }}>
            <FacebookIcon />
            <TwitterIcon />
            <InstagramIcon />
          </span>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justify="center"
        className="center"
        item
        sm={4}
      >
        <Grid
          style={{ marginBottom: "2em", minWidth: "200px" }}
          item
          align="center"
          md={2}
          sm={5}
        >
          <img src={logo} style={{ height: "65px", margin: "auto" }} />
        </Grid>
        <Grid container>
          <Grid item sm={4}>
            <Link to="about" smooth={true} duration={500}>
              About Us
            </Link>
          </Grid>
          <Grid item sm={4}>
            <Link to="process" smooth={true} duration={500}>
              Our Process
            </Link>
          </Grid>
          <Grid item sm={4}>
            <Link to="services" smooth={true} duration={500}>
              Services
            </Link>
          </Grid>
          <Grid item sm={4}>
            <Link to="maintenance" smooth={true} duration={500}>
              Maintenance
            </Link>
          </Grid>
          <Grid item sm={4}>
            <Link to="testimonials" smooth={true} duration={500}>
              Testimonials
            </Link>
          </Grid>
          <Grid item sm={4}>
            <Link to="contact" smooth={true} duration={500}>
              Contact
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="right" item sm={4}>
        <p>
          Powered by{" "}
          <a href="https://www.launchsitellc.com/" target="_blank">
            Launch Site LLC
          </a>
        </p>
        <p>All rights reserved &copy; 2020</p>
      </Grid>
    </Grid>
  );
};

export default DesktopFooter;
