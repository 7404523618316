import React from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import SectionHeading from "../utils/SectionHeading";
import LongIsland from "../../assets/images/long-island.png";
import BorderBox from "../utils/BorderBox";
import pond2 from "../../assets/images/rectangle-pond-2.png";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButton from "../utils/PrimaryButton";
import { Element } from "react-scroll";
import { Link, animateScroll as scroll } from "react-scroll";

const useStyles = makeStyles({
  gridMargin: {
    marginTop: "2em",
    marginBottom: "2em"
  },
  borderBox: {
    height: "100%",
    width: "100%",
    minHeight: "300px",
    minWidth: "330px"
  }
});

const title = "Beautiful Ponds Are Our Thing";
const paragraphOne = `We're passionate water enthusiasts that love to enhance ourdoor spaces with beautiful water 
features. We specialize in ponds, waterfalls and streams, and install aquascapes for both homes and businesses.`;
const paragraphTwo = `We can't wait to build your backyard oasis. We're just a call away!`;

const MobileWelcome = () => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.gridMargin}
      container
      justify="center"
      alignItems="stretch"
    >
      <Grid align="center" item sm={12}>
        <h1 className="section-heading">{title}</h1>
      </Grid>
      <Grid align="center" container item sm={12}>
        <div className="sectionLeftText">
          <h2>{paragraphOne}</h2>
        </div>
      </Grid>
      <Grid align="center" item sm={12}>
        <div className={classes.borderBox}>
          <BorderBox
            image={`url(${pond2})`}
            innerBorderColor="white"
            innerBorderRadius="10px"
            outerBorderRadius="10px"
            outerBorderColor="rgb(133, 139, 229, .56)"
          />
        </div>
      </Grid>
      <Grid align="center" container item sm={12}>
        <div className="sectionLeftText">
          <h2>{paragraphTwo}</h2>
        </div>
      </Grid>

      <Grid style={{ marginTop: "1.5em" }} item sm={12} align="center">
        <Link to="services" spy={true} smooth={true} duration={700}>
          <PrimaryButton variant="contained" color="primary">
            Our Services
          </PrimaryButton>
        </Link>
      </Grid>
    </Grid>
  );
};

const DesktopWelcome = () => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.gridMargin}
      container
      justify="center"
      alignItems="stretch"
    >
      <Grid item sm={12} md={5}>
        <Grid align="center" item sm={12} md={12}>
          <h1 className="section-heading">{title}</h1>
        </Grid>
        <Grid align="center" container item sm={12} md={12}>
          <div className="sectionLeftText">
            <h2>{paragraphOne}</h2>
            <h2>{paragraphTwo}</h2>
            <Grid style={{ marginTop: "1.5em" }} item sm={12} align="center">
              <Link to="services" spy={true} smooth={true} duration={700}>
                <PrimaryButton variant="contained" color="primary">
                  Our Services
                </PrimaryButton>
              </Link>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid align="center" item sm={12} md={5}>
        <div className={classes.borderBox}>
          <BorderBox
            image={`url(${pond2})`}
            innerBorderColor="white"
            innerBorderRadius="10px"
            outerBorderRadius="10px"
            outerBorderColor="rgb(133, 139, 229, .56)"
          />
        </div>
      </Grid>
    </Grid>
  );
};

const WelcomeSection = props => {
  const classes = useStyles();

  return (
    <Element name="about">
      <div className="features-heading welcomeSecion">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item sm={12}>
            <SectionHeading title={"Welcome, Long Islanders"} />
          </Grid>
          <Grid item md={12} sm={12}>
            <div
              style={{ height: "100%", width: "100%" }}
              className="verticalHorizontalDiv"
            >
              <div
                className={"containBackground longIslandImage"}
                style={{ backgroundImage: `url(${LongIsland})` }}
              />
            </div>
          </Grid>
          <Hidden mdUp>
            <MobileWelcome />
          </Hidden>
          <Hidden smDown>
            <DesktopWelcome />
          </Hidden>
        </Grid>
      </div>
    </Element>
  );
};

export default WelcomeSection;
