import React from "react";
import { Link, scroller, animateScroll as scroll } from "react-scroll";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
// import logo from "../../assets/images/eca-logo.png";
import logo from "../../assets/images/logoKoi2.png";

const useStyles = makeStyles({
  colorPrimary: {
    backdropFilter: "blur(5px)",
    boxShadow: "none",
    backgroundColor: "#3D9970",
    color: "white"
  }
});

const DesktopNavigationBar = () => {
  const classes = useStyles();
  return (
    <AppBar classes={{ colorPrimary: classes.colorPrimary }}>
      <Toolbar className="navigation textCenter">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item md={2} sm={5}>
            <Grid item md={12} sm={12}>
              <Button color="inherit">
                <Link to="home" smooth={true} duration={500}>
                  <img src={logo} style={{ height: "65px", margin: "auto" }} />
                </Link>
              </Button>
            </Grid>
            <Grid item md={12} sm={12}>
              <a style={{ color: "white" }} href="tel:+1-631-355-9546">
                {" "}
                <span className="phoneNumber whiteText">631-355-9546</span>
              </a>
            </Grid>
          </Grid>

          <Grid item md={2} sm={5}>
            <Button color="inherit">
              <Link to="about" smooth={true} duration={500}>
                About Us
              </Link>
            </Button>
          </Grid>
          <Grid item md={2} sm={5}>
            <Button color="inherit">
              <Link to="services" smooth={true} duration={500}>
                Services
              </Link>
            </Button>
          </Grid>

          <Grid item md={2} sm={5}>
            <Button color="inherit">
              <Link to="maintenance" smooth={true} duration={500}>
                Maintenance
              </Link>
            </Button>
          </Grid>
          <Grid item md={2} sm={5}>
            <Button color="inherit">
              <Link to="testimonials" smooth={true} duration={500}>
                Testimonials
              </Link>
            </Button>
          </Grid>
          <Grid item md={2} sm={5}>
            <Button color="inherit">
              <Link to="contact" smooth={true} duration={500}>
                Contact
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default DesktopNavigationBar;
