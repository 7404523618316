import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import { chunk } from "lodash";

class SleekGrid extends Component {
  state = {};

  createGrid = () => {
    const { items, cols } = this.props;

    const colWidth = 12 / cols;
    const rows = chunk(items, cols);
    const finalIndex = rows.length - 1;

    return rows.map((r, rowIndex) => (
      <Grid
        container
        item
        sm={12}
        md={12}
        style={{
          fontSize: "1em",
          fontWeight: "700",
          height: "275px"
        }}
      >
        {r.map((item, index) => {
          let style;

          if (rowIndex === finalIndex) {
            if ((index + 1) % cols == 0) {
              style = {};
            } else {
              style = {
                borderRight: "2px solid gray"
              };
            }
          } else {
            if ((index + 1) % cols == 0) {
              style = {
                borderBottom: "2px solid gray"
              };
            } else {
              style = {
                borderBottom: "2px solid gray",
                borderRight: "2px solid gray"
              };
            }
          }

          return (
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={style}
              sm={12}
              md={colWidth}
            >
              <Grid style={{ marginBottom: "2em" }} item align="center">
                <h3>{item.title}</h3>
              </Grid>
              <Grid item align="center">
                <p style={{ padding: "2em" }}>{item.description}</p>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    ));
  };

  render() {
    return (
      <div className="sleekGrid">
        <Grid container>{this.createGrid()}</Grid>
      </div>
    );
  }
}

export default SleekGrid;
