import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, InlineIcon } from "@iconify/react";
import fountainIcon from "@iconify/icons-mdi/fountain";
import shovelIcon from "@iconify/icons-si-glyph/shovel";
import outlineLandscape from "@iconify/icons-ic/outline-landscape";
import calendarOutlined from "@iconify/icons-ant-design/calendar-outlined";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import HotelIcon from "@material-ui/icons/Hotel";
import RepeatIcon from "@material-ui/icons/Repeat";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

const outerTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#3D9970"
    },
    lightGreen: {
      main: "#E6FEC4"
    }
  }
});

const useStyles = makeStyles(theme => ({
  descriptionConsultation: {
    padding: ".75em"
  },
  descriptionSurvey: {
    padding: "1.25em"
  },
  descriptionInstallation: {
    padding: "1.75em"
  },
  secondaryTail: {
    // backgroundColor: theme.palette.secondary.main
    backgroundColor: "#2E8CEA"
  },
  dotPadding: {
    padding: "10px"
  },
  root: {
    fontSize: "2em"
  }
}));

export default function CustomTimeline() {
  const classes = useStyles();

  return (
    <Fade>
      <ThemeProvider theme={outerTheme}>
        <Timeline className={`${classes.root} blackText`} align="alternate">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                style={{ backgroundColor: "#858BE5" }}
                className={classes.dotPadding}
              >
                <Icon icon={calendarOutlined} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>

            <TimelineContent>
              <div className={classes.descriptionConsultation}>
                <Typography className="blackText" variant="h6" component="h1">
                  Free Consultation
                </Typography>
                <Typography className="blackText">
                  We'll evaluate your property and ideas, and begin to
                  brainstorm ideas of our own
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="secondary" className={classes.dotPadding}>
                <Icon icon={outlineLandscape} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>

            <TimelineContent>
              <div className={classes.descriptionSurvey}>
                <Typography className="blackText" variant="h6" component="h1">
                  Survey Land & Draft Designs
                </Typography>
                <Typography className="blackText">
                  We'll pick the ideal location for your pond, and begin
                  drafting our ideas
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                style={{ backgroundColor: "#FF99A9" }}
                className={classes.dotPadding}
              >
                <Icon icon={shovelIcon} />
              </TimelineDot>
              <TimelineConnector className={classes.secondaryTail} />
            </TimelineSeparator>
            <TimelineContent>
              <div className={classes.descriptionInstallation}>
                <Typography className="blackText" variant="h6" component="h1">
                  Preparation & Installation
                </Typography>
                <Typography className="blackText">
                  We'll break ground and begin making your dream pond a reality!
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                style={{ backgroundColor: "#2E8CEA" }}
                className={classes.dotPadding}
              >
                <Icon icon={fountainIcon} />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <Typography className="blackText" variant="h6" component="h1">
                Enjoy!
              </Typography>
              <Typography className="blackText">
                Woohoo! It's time for you to enjoy your dream pond!
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </ThemeProvider>
    </Fade>
  );
}
