import React from "react";
import Hidden from "@material-ui/core/Hidden";
import DesktopFooter from "./DesktopFooter";
import MobileFooter from "./MobileFooter";

/**
 * The footer for the website, contains all navigation links and social media links. This footer
 * is responsive and will change to fit all screen sizes.
 *
 * @author Daniel Gomm <alphapaka89@gmail.com>
 * @copyright Launch Site LLC
 */
const Footer = () => {
  return (
    <>
      <div className="footer-separator"></div>
      <Hidden smDown>
        <DesktopFooter />
      </Hidden>
      <Hidden mdUp>
        <MobileFooter />
      </Hidden>
    </>
  );
};

export default Footer;
