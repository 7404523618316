import React from "react";
import "../src/assets/css/App.css";
import "bootstrap/dist/css/bootstrap.css";
import NavigationBar from "./components/Navigation/NavigationBar";
import AboutSection from "./components/AboutSection/About";
import ContactSection from "./components/ContactSection/ContactSection";
import Footer from "./components/Footer/Footer.jsx";
import BlobHeroSection from "./components/BlobHeroSection/BlobHeroSection";
import WelcomeSection from "./components/WelcomeSection/WelcomeSection";
import ServicesSection from "./components/ServicesSection/ServicesSection";
import TestimonialsSection from "./components/TestimonialsSection/TestimonialsSection";
import MaintenanceSection from "./components/MaintenanceSection/MaintenanceSection";
import InstagramSection from "./components/InstagramSection/InstagramSection";
import OurProcessSection from "./components/OurProcessSection/OurProcessSection";

function App() {
  return (
    <>
      <NavigationBar />
      <BlobHeroSection />
      <WelcomeSection />
      <AboutSection />
      <ServicesSection />
      <OurProcessSection />
      <MaintenanceSection />
      <InstagramSection />
      <TestimonialsSection />
      <ContactSection />
      <Footer />
    </>
  );
}

export default App;
