import React from "react";
import ContactForm from "../utils/ContactForm/ContactForm";
import Grid from "@material-ui/core/Grid";
import SectionHeading from "../utils/SectionHeading";
import koi from "../../assets/images/koi-fish-cutout-4.png";
import BlobContainer from "../utils/BlobContainer";
import Fade from "react-reveal/Fade";
import { Element } from "react-scroll";

const ContactSection = (props) => {
  return (
    <Element name="contact">
      <div className="verticalHorizontalDiv ">
        <BlobContainer
          height="100vh"
          width="80vw"
          className="contactSection"
          backgroundImage={koi}
          innerComponent={
            <Grid container justify="center" alignContent="center">
              <Grid style={{ marginBottom: "1em" }} align="center" item sm={12}>
                <SectionHeading title="Let's Chat" />
              </Grid>
              <Grid align="center" item sm={12} md={4}>
                <Grid item align="left" md={12} sm={12}>
                  <div className="blackText features-heading sectionText contactText">
                    <h2>
                      Get your free quote today, and let's make your dream pond
                      a reality!
                    </h2>
                    <h2 className="callNow">
                      Call now, or fill out the form and we'll get back to you
                      ASAP. Cheers!
                    </h2>
                  </div>
                </Grid>
                <Grid item align="left" md={12} sm={12}>
                  <div
                    style={{ marginBottom: "5em" }}
                    className="blackText features-heading sectionText contactText"
                  >
                    <a href="tel:+1-631-355-9546">
                      {" "}
                      <span className="phoneNumber blackText phoneNumberContact contactText">
                        631-355-9546
                      </span>
                    </a>
                  </div>
                </Grid>
              </Grid>

              <Grid item sm={12} md={4} align="center">
                <ContactForm />
              </Grid>
            </Grid>
          }
        />
      </div>
    </Element>
  );
};

export default ContactSection;
