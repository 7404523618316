import React from "react";
import Grid from "@material-ui/core/Grid";
import BlobContainer from "../utils/BlobContainer";
import blob from "../../assets/images/purple-blob.png";
import SectionHeading from "../utils/SectionHeading";
import Fade from "react-reveal/Fade";

import SleekGrid from "../utils/SleekGrid";
import PrimaryButton from "../utils/PrimaryButton";
import { Element } from "react-scroll";
import { Hidden } from "@material-ui/core";
import GridSection from "../utils/GridUtils/GridSection/GridSection";
import { GridSectionItemDTO } from "../utils/GridUtils/GridSectionItemDTO/GridSectionItemDTO";
import { Link, animateScroll as scroll } from "react-scroll";

const items = [
  new GridSectionItemDTO({
    title: "Aquascape Weed Removal",
    description:
      "We'll completely remove all exterior weeds, and trim all overgrowth",
    color: "#323258"
  }),
  new GridSectionItemDTO({
    title: "Pond Waste Removal",
    description:
      "We'll completely remove all pond waste, and refresh the water",
    color: "#3d996f"
  }),
  new GridSectionItemDTO({
    title: "Power Washing",
    description: "We'll power wash all pond rocks and water features",
    color: "#adbce0"
  }),
  new GridSectionItemDTO({
    title: "Filter Maintenance",
    description: "We'll clean out all filters and skimmers",
    color: "#68aaf0"
  }),
  new GridSectionItemDTO({
    title: "Facilitate Biological Cycle",
    description:
      "We'll fill your pond with clean water and start the biological cycle",
    color: "#3075b8"
  }),
  new GridSectionItemDTO({
    title: "Fish & Ecosystem Maintenance",
    description: "We'll make sure your pond plant and animal life are healthy",
    color: "#323258"
  })
];

const MobileMaintenance = () => {
  return (
    <div
      style={{ marginTop: "10em" }}
      className="maintenanceSection verticalHorizontalDiv"
    >
      <Grid
        style={{ height: "100%" }}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid style={{ marginBottom: "2em" }} item sm={12} md={12}>
          <SectionHeading title="Maintenance" />
        </Grid>
        <Grid style={{ height: "100%" }} item>
          <GridSection
            colsLg={3}
            colsSm={2}
            colsXs={1}
            items={items}
            tileClasses="blackText maintenanceGridTiles "
          />
        </Grid>
      </Grid>
    </div>
  );
};

const DesktopMaintenance = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "relative"
      }}
      className="verticalRightDiv"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flexEnd",
          zIndex: 1
        }}
      >
        <BlobContainer height="100vh" width="75vw" backgroundImage={blob} />
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 1000,
          transform: "translateY(20%)"
        }}
      >
        <Grid
          className="blackText"
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid style={{ marginBottom: "5em" }} item sm={12} md={12}>
            <SectionHeading title="Maintenance & Prep Work" />
          </Grid>
          <Grid item>
            <SleekGrid cols={3} items={items} />
          </Grid>
          <Grid style={{ marginTop: "1.5em" }} item sm={12} align="center">
            <Link to="contact" spy={true} smooth={true} duration={700}>
              <PrimaryButton variant="contained" color="primary">
                Contact Us
              </PrimaryButton>
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const MaintenanceSection = props => {
  return (
    <Element name="maintenance">
      <Fade>
        <Hidden mdUp>
          <MobileMaintenance />
        </Hidden>
        <Hidden smDown>
          <DesktopMaintenance />
        </Hidden>
      </Fade>
    </Element>
  );
};

export default MaintenanceSection;
