import React from "react";
import Grid from "@material-ui/core/Grid";

import koiPond from "../../assets/images/koipond.jpeg";
import pondEcosystems from "../../assets/images/pond-ecosystems.jpeg";
import pondLandscape from "../../assets/images/pond-landscape.png";
import pondMaintenance from "../../assets/images/maintenance.jpeg";
import aquascapeLighting from "../../assets/images/pond-lighting.jpeg";
import waterfallPond from "../../assets/images/waterfall-pond.png";
import waterfallLitUpPond from "../../assets/images/waterfall-lit-up.jpeg";
import customWaterFeature from "../../assets/images/custom-water-feature.jpeg";
import fountain from "../../assets/images/fountain-1.jpg";
import SectionHeading from "../utils/SectionHeading";
import blueBlob from "../../assets/images/blue-blob.png";
import greenBlob from "../../assets/images/lilyGreenBlob.png";

import Fade from "react-reveal/Fade";

import BlobContainer from "../utils/BlobContainer";

import GridSection from "../utils/GridUtils/GridSection/GridSection";
import { GridSectionItemDTO } from "../utils/GridUtils/GridSectionItemDTO/GridSectionItemDTO";
import { Element } from "react-scroll";

const items = [
  new GridSectionItemDTO({
    title: "Standard Ponds",
    subtitle: "",
    src: pondLandscape,
  }),
  new GridSectionItemDTO({
    title: "Koi Ponds",
    subtitle: "",
    src: koiPond,
  }),
  new GridSectionItemDTO({
    title: "Pond Ecosystems",
    subtitle: "",
    src: pondEcosystems,
  }),
  new GridSectionItemDTO({
    title: "Pond Maintenance",
    subtitle: "",
    src: pondMaintenance,
  }),
  new GridSectionItemDTO({
    title: "Aquascaope Lighting",
    subtitle: "",
    src: aquascapeLighting,
  }),
  new GridSectionItemDTO({
    title: "Waterfall Ponds",
    subtitle: "",
    src: waterfallPond,
  }),
  new GridSectionItemDTO({
    title: "Pond Lighting",
    subtitle: "",
    src: waterfallLitUpPond,
  }),
  new GridSectionItemDTO({
    title: "Custom Water Feature",
    subtitle: "",
    src: customWaterFeature,
  }),
  new GridSectionItemDTO({
    title: "Fountains",
    subtitle: "",
    src: fountain,
  }),
];

const ServicesSection = (props) => {
  return (
    <Element name="services">
      <Fade>
        <div className="servicesSection verticalHorizontalDiv">
          <BlobContainer
            height="100%"
            width="100vw"
            backgroundImage={greenBlob}
            innerComponent={
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <Grid style={{ marginBottom: "2em" }} item sm={12} md={12}>
                  <SectionHeading title="Services" />
                </Grid>
                <Grid style={{ height: "100%" }} item>
                  <GridSection
                    colsLg={3}
                    colsSm={2}
                    colsXs={2}
                    items={items}
                    tileClasses="servicesGridTitles roundedDiv boxShadow"
                  />
                </Grid>
              </Grid>
            }
          />
        </div>
      </Fade>
    </Element>
  );
};

export default ServicesSection;
