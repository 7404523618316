import React from "react";
import Slider from "react-slick";
import StarRatings from "react-star-ratings";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Fade from "react-reveal/Fade";

import SliderComponent from "./SliderComponent";
import { Hidden } from "@material-ui/core";

function Testimonials(props) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div
      className="verticalHorizontalDiv"
      style={{
        height: "100%",
        position: "relative",
        zIndex: 1000
      }}
    >
      <div
        style={{
          height: "auto"
        }}
      >
        <Container className="testimonials">
          <Grid
            direction="column"
            container
            justify="center"
            alignItems="center"
          >
            <Grid align="center" justify="center" item sm={12}>
              <h1>{props.title}</h1>
            </Grid>
            <Grid align="center" justify="center" item sm={12}>
              <Hidden mdUp>
                <StarRatings
                  rating={5}
                  starDimension="2em"
                  starRatedColor="#EE5201"
                  numberOfStars={5}
                  name="rating"
                />
              </Hidden>
              <Hidden smDown>
                <StarRatings
                  rating={5}
                  starDimension="2.25em"
                  starRatedColor="#EE5201"
                  numberOfStars={5}
                  name="rating"
                />
              </Hidden>
              <h2 style={{ marginTop: "2em" }} className="description">
                {props.subTitle}
              </h2>
            </Grid>
            <Slider {...settings}>
              {props.items.map(item => (
                <SliderComponent
                  imageSrc={item.imageSrc}
                  headerText={item.header}
                  subText={item.subHeader}
                  description={item.description}
                  rating={item.rating}
                />
              ))}
            </Slider>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default Testimonials;
